var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('AssetListMolecule', {
    attrs: {
      "isLoading": _vm.isLoading,
      "assets": _vm.assetsMapped,
      "interval": _vm.interval
    }
  }), _vm.shouldShowLoadMoreButton ? _c('LoadMoreButtonMolecule', {
    attrs: {
      "is-loading": _vm.$apollo.queries.assetSeveritiesCount.loading
    },
    on: {
      "load-more-rows": _vm.loadMoreAssets
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }