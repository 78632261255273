<template>
  <KeyValueAtomStyled>
    <span class="label muted">{{ keyString }}: </span>
    <span class="value">{{ valueString }}</span>
  </KeyValueAtomStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'

const KeyValueAtomStyled = styled('div')`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 0.5rem;
  align-items: center;
  .label {
    display: flex;
    justify-content: flex-end;
    text-transform: capitalize;
  }
  .muted {
    color: ${({ theme }) => (theme.isDark ? theme.colors.muted : theme.colors.dimGrey)};
  }
`
export default {
  components: {
    KeyValueAtomStyled,
  },
  props: {
    keyString: {
      type: String,
      default: null,
    },
    valueString: {
      type: String,
      default: null,
    },
  },
}
</script>
