<template>
  <FilterLabelAtomStyled :class="{ muted: filter.isDisabled, expanded: isExpanded }">{{ filter.label | capitalize }}</FilterLabelAtomStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const FilterLabelAtomStyled = styled('strong')`
  font-size: 14px;
  justify-self: start;
  &.muted {
    color: ${({ theme }) => theme.colors.muted};
  }
  &.expanded {
    justify-self: start;
    @media (min-width: 896px) {
      justify-self: end;
    }
  }
`

export default {
  props: {
    isExpanded: {
      type: Boolean,
      required: true,
    },
    filter: {
      type: Object,
      required: true,
    },
  },
  components: {
    FilterLabelAtomStyled,
  },
}
</script>
