var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('HeaderMoleculeStyled', [_c('HeartIconAtom', {
    attrs: {
      "iconName": _vm.iconName
    }
  }), _c('TitleAtom'), _c('FiltersMolecule', {
    attrs: {
      "isLoading": _vm.isLoading,
      "filters": _vm.filters
    },
    on: {
      "select": function select($event) {
        return _vm.$emit('select', $event);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }