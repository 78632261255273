<template>
  <FilterValueAtomStyled :class="{ muted: filter.isDisabled }">
    <LoadingDots v-if="isLoading" />
    <span v-else class="value">{{ filter.value?.label ?? '-' }}</span>
  </FilterValueAtomStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { LoadingDots } from '@common/components'

const FilterValueAtomStyled = styled('div')`
  display: flex;
  gap: 0.5rem;
  font-size: 14px;
  padding: 0 0.75rem 0 0.25rem;
  &.muted {
    color: ${({ theme }) => theme.colors.muted};
  }
`

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    filter: {
      type: Object,
      required: true,
    },
  },
  components: {
    FilterValueAtomStyled,
    LoadingDots,
  },
}
</script>
