var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SeverityLampWithCountStyled', [_c('SeverityLampAtomV2Styled', {
    class: [_vm.classes]
  }, [_vm.severityEnum && _vm.severityEnum === 'OK' ? _c('CheckIcon') : _vm._e(), _vm.severityEnum && _vm.severityEnum === 'NODATA' ? _c('XIcon') : _vm._e()], 1), _vm.showCount ? _c('span', {
    staticClass: "count"
  }, [_vm._v(" " + _vm._s(_vm.count) + " ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }