var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ActiveSeverityPieChartMoleculeStyled', [_c('PieChart', {
    attrs: {
      "option": _vm.option
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }