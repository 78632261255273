var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetIssueStyled', [_c('div', {
    staticClass: "information"
  }, [_c('div', {
    staticClass: "start"
  }, [_c('span', {
    staticClass: "muted"
  }, [_vm._v(_vm._s(_vm._f("capitalize")(_vm.$tc('start'))) + ":")]), _c('span', [_vm._v(_vm._s(_vm.start))])]), _c('div', {
    staticClass: "end"
  }, [_c('span', {
    staticClass: "muted"
  }, [_vm._v(_vm._s(_vm._f("capitalize")(_vm.$tc('end'))) + ":")]), _c('span', [_vm._v(_vm._s(_vm.end))])]), _c('div', {
    staticClass: "duration"
  }, [_c('span', {
    staticClass: "muted"
  }, [_vm._v(_vm._s(_vm._f("capitalize")(_vm.$tc('duration'))) + ":")]), _c('span', [_vm._v(_vm._s(_vm.duration))])])]), _c('div', {
    staticClass: "actions"
  }, [_c('GoToAtom', {
    attrs: {
      "to": _vm.to,
      "label": _vm.label
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }