<template>
  <TalpaLoaderWrapper v-if="$apollo.queries.assetIssues.loading" />
  <AssetIssuesGroupedStyled v-else>
    <h4>{{ $t('maintenance.insights') }}:</h4>
    <GoToAtom v-if="assetId" :to="to" :label="label" />
    <template v-for="[key, value] in groupedIssues">
      <ListWrapperStyled :key="key + '_row'" @click="toggleIssueTab(key)">
        <div class="action">
          <ChevronRightIcon class="icon" :class="{ rotate: isDetailsExpanded && id === key }" />
        </div>
        <div class="count">
          <CircleBadge class="filterButton" :label="value.issues.length" size="M" />
        </div>
        <div class="code">
          <strong>{{ $tc('code', 1) | capitalize }}:</strong>
          <span>#{{ value.code }}</span>
        </div>
        <div class="severities">
          <SeverityLampsMolecule :severities="Array.from(value.severities)" :activeIssues="value.activeIssues" />
        </div>
        <div class="label">
          <strong>{{ $tc('label', 1) | capitalize }}:</strong>
          <span>{{ value.label }}</span>
        </div>
        <div class="duration">
          <strong>{{ $tc('duration', 1) | capitalize }}:</strong>
          <span> {{ value.duration | secondsTohhmmss }} </span>
        </div>
        <div class="message">
          <strong>{{ $tc('description', 1) | capitalize }}:</strong>
          <span>{{ value.description }}</span>
        </div>
      </ListWrapperStyled>
      <DetailsStyled :key="key + '_details'" v-if="isDetailsExpanded && id === key">
        <AssetIssueMolecule v-for="(issue, i) in value.issues" :key="issue.id + '_subRow' + i" :issue="issue" :asset="mappedAsset" />
      </DetailsStyled>
    </template>
  </AssetIssuesGroupedStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { groupBy, orderBy } from 'lodash'
import { ChevronRightIcon } from 'vue-feather-icons'
import { buttonReset } from '@styles/mixins'
import { secondsTohhmmss } from '@/utils/filters/time'
import { TalpaLoaderWrapper } from '@common/components'
import AssetIssueMolecule from './AssetIssuesGroupedMolecule/AssetIssueMolecule'
import SeverityLampsMolecule from '@/components/Atomic/Molecules/SeverityLampsMolecule'
import GoToAtom from '@/components/Atomic/Atoms/GoToAtom.vue'
import CircleBadge from '@/components/Atomic/Atoms/CircleBadge'
import ASSETS_ISSUES_BASIC_QUERY from '#/graphql/operations/maintenance/healthExplorer/assetIssuesBasicQuery.gql'

const AssetIssuesGroupedStyled = styled('div')`
  user-select: none;
  margin: 0.5rem;
  padding: 1rem;
  grid-column: span 7;
  background-color: ${({ theme }) => (theme.isDark ? theme.colors.panelBG : theme.colors.whiteSmokeDark)};
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-auto-rows: min-content;
  grid-gap: 0.5rem;

  > h4 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`
const ListWrapperStyled = styled('div')`
  grid-column: span 2;
  cursor: pointer;
  display: grid;
  align-items: center;
  grid-template-columns: min-content min-content 10rem max-content 1fr 1fr 10rem;
  grid-template-areas:
    'chevron count code severities label duration acknowledge'
    'chevron count message message message message acknowledge';
  grid-gap: 0.75rem;
  background: ${({ theme }) => theme.colors.solidBG};
  box-shadow: 0px 1px 4px ${({ theme }) => chroma(theme.colors.black).alpha(0.1).css()};
  padding: 0.5rem;
  border-radius: 0.5rem;
  @media (max-width: 1024px) {
    grid-template-columns: min-content min-content 1fr 1fr 1fr;
    grid-template-rows: min-content;
    grid-template-areas:
      'chevron count code severities'
      'chevron count label label'
      'chevron count duration duration'
      'chevron count message message'
      'chevron count acknowledge acknowledge';
    .message,
    .duration,
    .label {
      align-items: flex-start;
      flex-direction: column;
    }
    .acknowledge {
      justify-self: center;
    }
  }
  &:hover {
    > .toggle,
    background-color: ${({ theme }) =>
      theme.isDark ? chroma(theme.colors.solidBG).brighten(0.5).css() : chroma(theme.colors.widgetBG).darken(0.2).css()};
  }
  > .action {
    grid-area: chevron;
    ${p => buttonReset(p)}
    color: ${p => p.theme.colors.primary};
    padding: 0;
    &:hover {
      color: ${p => p.theme.colors.primaryLighter};
    }
    .icon {
      flex-shrink: 0;
      overflow: hidden;
      transition: transform 0.25s;
      &.rotate {
        transform: rotate(90deg);
      }
    }
  }
  > .count {
    grid-area: count;
  }
  .code,
  .label,
  .duration,
  .message {
    display: flex;
    gap: 0.25rem;
  }

  .code {
    grid-area: code;
    align-items: center;
  }
  .label {
    grid-area: label;
  }
  .severities {
    grid-area: severities;
  }
  .message {
    grid-area: message;
  }
  .duration {
    grid-area: duration;
  }
  .acknowledge {
    &:active {
      transform: translateY(1px);
    }
    grid-area: acknowledge;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 12rem;
    gap: 0.5rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    color: ${p => p.theme.colors.primary};
    background: ${({ theme }) => (theme.isDark ? theme.colors.solidBG : theme.colors.mainBG)};
    border: 1px solid
      ${({ theme }) => (theme.isDark ? chroma(theme.colors.white).alpha(0.16).css() : chroma(theme.colors.black).alpha(0.01).css())};
    padding: 0.25rem 0.5rem;
    > strong {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &:hover {
      color: ${p => p.theme.colors.primaryLighter};
    }
    &:disabled {
      opacity: 0.8;
      color: ${p => p.theme.colors.muted};
    }
  }
`

const DetailsStyled = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1rem 2rem;
`

export default {
  inject: ['uiSettings'],
  components: {
    AssetIssuesGroupedStyled,
    ListWrapperStyled,
    DetailsStyled,
    AssetIssueMolecule,
    ChevronRightIcon,
    SeverityLampsMolecule,
    GoToAtom,
    CircleBadge,
    TalpaLoaderWrapper,
  },
  data() {
    return {
      isDetailsExpanded: false,
      id: null,
      assetIssues: [],
    }
  },
  props: {
    assetId: {
      type: String,
      required: true,
    },
    interval: {
      type: String,
      required: true,
    },
  },
  filters: {
    secondsTohhmmss,
  },
  computed: {
    locale() {
      return (this.uiSettings?.dates ?? 'DE_DE').replace('_', '-')
    },
    userLocaleForQuery() {
      return this.locale.slice(0, 2).toLowerCase() + this.locale.slice(2, 5)
    },
    routeName() {
      return this.$route.meta?.isSDApp ? 'ServiceDealerAssetHealthOverview' : 'AssetHealthOverview'
    },
    mappedAsset() {
      const mappedAsset = { id: this.assetId }
      mappedAsset.groupedIssues = groupBy(this.assetIssues, 'code')
      return mappedAsset
    },
    groupedIssues() {
      const issueGroupssMap = new Map()
      const orderedIssues = orderBy(this.assetIssues, 'isActive', 'desc')
      return orderedIssues.reduce((acc, assetIssue) => {
        const severities = [...assetIssue.severities].sort((a, b) => a < b)
        const id = `${assetIssue.code}-${severities.join('-')}-${assetIssue.isAcknowledged}`
        let issueGroup = acc.get(id)
        if (!issueGroup) {
          issueGroup = {
            id,
            code: assetIssue.code,
            issues: [assetIssue],
            highestSeverity: assetIssue.highestSeverity,
            severities: severities,
            label: assetIssue.label,
            description: assetIssue.description,
            isAcknowledged: assetIssue.isAcknowledged,
            duration: assetIssue.duration,
            activeIssues: [],
          }
          acc.set(id, issueGroup)
        } else {
          issueGroup.duration = issueGroup.duration + assetIssue.duration
          issueGroup.issues.push(assetIssue)
        }
        if (assetIssue.isActive) {
          issueGroup.activeIssues = assetIssue.severities
        }
        return acc
      }, issueGroupssMap)
    },
    to() {
      return {
        name: this.routeName,
        params: {
          id: this.assetId,
        },
      }
    },
    label() {
      return this.$tc('actions.goToOverviews')
    },
  },
  methods: {
    cell(severity) {
      return {
        color: severity,
      }
    },
    toggleIssueTab(id) {
      if (this.id === id) {
        this.id = null
        this.isDetailsExpanded = false
      } else {
        this.id = id
        this.isDetailsExpanded = true
      }
    },
    getIssueIds(assetIssues) {
      return assetIssues.map(assetIssue => assetIssue?.id)
    },
  },
  apollo: {
    assetIssues: {
      query: ASSETS_ISSUES_BASIC_QUERY,
      variables() {
        return {
          where: {
            interval: this.interval,
            assetIds: [this.assetId],
            locale: this.userLocaleForQuery,
          },
        }
      },
      skip() {
        return !this.userLocaleForQuery || !this.assetId || !this.interval
      },
    },
  },
}
</script>
