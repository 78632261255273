<template>
  <SummaryByAssetTypeMolecule v-if="assetsBySeverity" :fleetComposition="fleetComposition" :isLoading="isLoading" />
</template>

<script>
import SummaryByAssetTypeMolecule from './SummaryByAssetTypeMolecule'

export default {
  props: {
    assetsBySeverity: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
    },
  },
  components: {
    SummaryByAssetTypeMolecule,
  },
  data() {
    return {
      assets: [],
      severityEnums: ['PRIORITY', 'ERROR', 'WARNING', 'MALFUNCTION', 'PROTECTION', 'OK', 'NODATA'],
    }
  },
  computed: {
    severityMapper() {
      return {
        Priority: 'PRIORITY',
        Red: 'ERROR',
        Yellow: 'WARNING',
        Malfunction: 'MALFUNCTION',
        Protection: 'PROTECTION',
        Green: 'OK',
        NoData: 'NODATA',
      }
    },
    fleetComposition() {
      const mappedAssetsWithSeverity = []
      const assetIdsSet = new Set()

      this.assetsBySeverity?.forEach(item => {
        item.assets.forEach(asset => {
          const assetKey = asset.id
          if (!assetIdsSet.has(assetKey)) {
            mappedAssetsWithSeverity.push({
              ...asset,
              highestSeverityEnum: this.severityMapper[item.severity],
            })
            assetIdsSet.add(assetKey)
          }
        })
      })
      const initial = new Map([
        [
          '_all',
          this.severityEnums.reduce((init, severityEnum) => {
            init.set(severityEnum, 0)
            return init
          }, new Map()),
        ],
      ])
      const assets = mappedAssetsWithSeverity?.reduce((map, asset) => {
        const allCount = map.get('_all').get(asset.highestSeverityEnum)
        map.get('_all').set(asset.highestSeverityEnum, allCount + 1)
        const machineType = map.get(asset.type.name)
        if (machineType) {
          const count = machineType.get(asset.highestSeverityEnum)
          machineType.set(asset.highestSeverityEnum, count + 1)
        } else {
          const initMachineTypes = this.severityEnums.reduce((init, severityEnum) => {
            init.set(severityEnum, severityEnum === asset.highestSeverityEnum ? 1 : 0)
            return init
          }, new Map())
          map.set(asset.type.name, initMachineTypes)
        }
        return map
      }, initial)
      return assets
    },
  },
}
</script>
