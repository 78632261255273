var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('NoDataAtomStyled', {
    class: {
      'margin-v': _vm.marginV
    }
  }, [_vm._v(_vm._s(_vm.$t('messages.noDataAvailable')))]);
}
var staticRenderFns = []

export { render, staticRenderFns }