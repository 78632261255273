<template>
  <AssetListMoleculeStyled>
    <TalpaLoaderWrapper v-if="isLoading" />
    <NoDataAtom v-else-if="assets?.length < 1" />
    <template v-else>
      <template v-for="asset in assets">
        <AssetMolecule class="row" :key="asset.id" :asset="asset" :interval="interval" />
      </template>
    </template>
    <ScrollToTopButtonMolecule :containerId="'app-main'" :scrollThreshold="2500" />
  </AssetListMoleculeStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'

import { TalpaLoaderWrapper, ScrollToTopButtonMolecule } from '@common/components'

import AssetMolecule from './AssetListMolecule/AssetMolecule'

import NoDataAtom from '@/components/Atomic/Atoms/NoDataAtom'

const AssetListMoleculeStyled = styled('div')`
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.widgetBG};
  min-height: 2rem;
  padding: 0.5rem 0;
  border-radius: 0.5rem;
`
export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    interval: {
      type: String,
      required: true,
    },
    assets: {
      type: Array,
    },
  },
  components: {
    AssetListMoleculeStyled,
    TalpaLoaderWrapper,
    NoDataAtom,
    AssetMolecule,
    ScrollToTopButtonMolecule,
  },
}
</script>
