var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.assetsBySeverity ? _c('SummaryByAssetTypeMolecule', {
    attrs: {
      "fleetComposition": _vm.fleetComposition,
      "isLoading": _vm.isLoading
    }
  }) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }