<template>
  <SeverityLampsMoleculeV2Styled>
    <template v-for="[severityEnum, count] of severityData">
      <SeverityLampAtomV2
        v-if="severityEnum !== 'OK' && severityEnum !== 'NODATA'"
        :key="severityEnum"
        :severityEnum="severityEnum"
        :active="count > 0"
        :count="count"
        :showCount="showCounts"
      />
    </template>
    <SeverityLampAtomV2
      v-if="showOkLamp"
      :severityEnum="'OK'"
      :active="(severityData?.get('OK') ?? 0) > 0"
      :count="severityData?.get('OK')"
      :showCount="showCounts"
    />
    <SeverityLampAtomV2
      v-if="showNoData"
      :severityEnum="'NODATA'"
      :active="(severityData?.get('NODATA') ?? 0) > 0"
      :count="severityData?.get('NODATA')"
      :showCount="showCounts"
    />
  </SeverityLampsMoleculeV2Styled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import SeverityLampAtomV2 from '@/components/Atomic/Atoms/SeverityLampAtomV2'

const SeverityLampsMoleculeV2Styled = styled('div')`
  display: grid;
  grid-template-rows: 1fr;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  align-items: center;
`

export default {
  props: {
    severityData: {
      type: Map,
      required: true,
    },
    showOkLamp: {
      type: Boolean,
      default: false,
    },
    showNoData: {
      type: Boolean,
      default: false,
    },
    onlyHighestSeverity: {
      type: Boolean,
      default: false,
    },
    showCounts: {
      type: Boolean,
      default: false,
    },
    hasBackground: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SeverityLampsMoleculeV2Styled,
    SeverityLampAtomV2,
  },
}
</script>
