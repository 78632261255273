<template>
  <AssetNameMoleculeStyled>
    <div v-if="asset && asset.name === undefined"><LoadingDots /></div>
    <div class="icon">
      <TalpaIcon class="asset-icon" :scope="'AssetTypes'" :name="iconName" />
    </div>
    <div class="name">
      {{ asset.name }}
    </div>
  </AssetNameMoleculeStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import { TalpaIcon } from '@common/components'
import { LoadingDots } from '@common/components'

const AssetNameMoleculeStyled = styled('div')`
  display: grid;
  grid-template-columns: 2.5rem 1fr;
  grid-template-rows: 1fr;
  .icon {
    display: flex;
    align-items: center;
  }
  .name {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    color: ${({ theme }) => theme.colors.primary};
  }
`
export default {
  components: {
    AssetNameMoleculeStyled,
    TalpaIcon,
    LoadingDots,
  },
  props: {
    asset: {
      type: Object,
      required: true,
    },
  },
  computed: {
    iconName() {
      return this.asset?.type?.name?.split(' ').join('') ?? ''
    },
  },
}
</script>
