<template>
  <AssetIssueCountsStyled>
    <SeverityCountStyled>
      <SeverityLampAtom :class="{ priority: severities.priorityCount.count > 0, active: severities.priorityCount.isActive }">
      </SeverityLampAtom>
      <strong>{{ severities.priorityCount.count }}</strong>
    </SeverityCountStyled>
    <SeverityCountStyled>
      <SeverityLampAtom :class="{ red: severities.errorCount.count > 0, active: severities.errorCount.isActive }"> </SeverityLampAtom>
      <strong>{{ severities.errorCount.count }}</strong>
    </SeverityCountStyled>
    <SeverityCountStyled>
      <SeverityLampAtom :class="{ amber: severities.warningCount.count > 0, active: severities.warningCount.isActive }"> </SeverityLampAtom>
      <strong>{{ severities.warningCount.count }}</strong>
    </SeverityCountStyled>
    <SeverityCountStyled>
      <SeverityLampAtom :class="{ malfunction: severities.malfunctionCount.count > 0, active: severities.malfunctionCount.isActive }">
      </SeverityLampAtom>
      <strong>{{ severities.malfunctionCount.count }}</strong>
    </SeverityCountStyled>
    <SeverityCountStyled>
      <SeverityLampAtom :class="{ protection: severities.protectionCount.count > 0, active: severities.protectionCount.isActive }">
      </SeverityLampAtom>
      <strong>{{ severities.protectionCount.count }}</strong>
    </SeverityCountStyled>
    <SeverityCountStyled>
      <SeverityLampAtom :class="{ green: isGreenEnabledWithOperatingTime }">
        <template v-slot:icon>
          <CheckIcon />
        </template>
      </SeverityLampAtom>
    </SeverityCountStyled>
  </AssetIssueCountsStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import { CheckIcon } from 'vue-feather-icons'

import SeverityLampAtom from '@/components/Atomic/Atoms/SeverityLampAtom'

const AssetIssueCountsStyled = styled('div')`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-content: space-between;
  .feather-check {
    margin-top: 1px;
    width: 14px;
    height: 14px;
    color: ${({ theme }) => theme.colors.atomic.iconBG};
  }
  .green {
    .feather-check {
      color: ${({ theme }) => theme.colors.atomic.white};
    }
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 5.5rem);
    justify-content: space-between;
  }
`
const SeverityCountStyled = styled('div')`
  display: flex;
  align-items: center;
  > strong {
    margin-left: 0.5rem;
  }

  &.active {
    > strong,
    .icon {
      opacity: 1;
    }
    .icon {
      filter: saturate(1);
    }
  }
  @media (max-width: 425px) {
    flex-direction: ${props => (props.reArraganceCountOnSmallScreen ? 'column' : 'row')};
    > strong {
      margin-left: ${props => (props.reArraganceCountOnSmallScreen ? '0rem' : '0.5rem')};
    }
  }
`
export default {
  components: {
    AssetIssueCountsStyled,
    SeverityCountStyled,
    SeverityLampAtom,
    CheckIcon,
  },
  props: {
    severities: {
      type: Object,
      required: true,
    },
    isGreenEnabledWithOperatingTime: {
      type: Boolean,
    },
  },
  computed: {},
}
</script>
