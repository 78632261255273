<template>
  <div>
    <AssetListMolecule :isLoading="isLoading" :assets="assetsMapped" :interval="interval" />
    <LoadMoreButtonMolecule
      v-if="shouldShowLoadMoreButton"
      :is-loading="$apollo.queries.assetSeveritiesCount.loading"
      @load-more-rows="loadMoreAssets"
    />
  </div>
</template>
<script>
import AssetListMolecule from './AssetListMolecule'
import { getAssetDimensionNameByLocale } from '@common/utils/src'
import { LoadMoreButtonMolecule } from '@common/components'
import isEqual from 'lodash/isEqual'
import ASSETS_WITH_KPI_AND_LAST_POSITION from '#/graphql/operations/maintenance/assetsWithKPIAndLastPositionQuery.gql'
import ASSET_SEVERITIES_COUNT_QUERY from '#/graphql/operations/maintenance/assetSeveritiesCountQuery.gql'
import ASSET_OPERATING_TIME_TRANSLATION from '#/graphql/assetDimensions/assetOperatingTimeTranslation.gql'

export default {
  inject: ['uiSettings'],
  props: {
    selectedSeverityId: {
      type: String,
      required: true,
    },
    assetIds: {
      type: Array,
    },
    interval: {
      type: String,
    },
    isParentLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AssetListMolecule,
    LoadMoreButtonMolecule,
  },
  watch: {
    assetIds: {
      handler(newAssetIds, oldAssetIds) {
        if (!isEqual(newAssetIds, oldAssetIds)) {
          this.reset()
          this.assetsSeveritiesList = []
        }
      },
      immediate: true,
    },
    selectedSeverityId: {
      handler(newSeverityId, oldSeverityId) {
        if (newSeverityId !== oldSeverityId) {
          this.reset()
        }
      },
      immediate: true,
    },
    queryParams: {
      handler(newParams, oldParams) {
        if (newParams !== oldParams) {
          this.reset()
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      limit: 50,
      offset: 0,
      currentOffsetAssetIds: [],
      assetOperatingTimeTranslation: null,
      assetsSeveritiesList: [],
      assetsKPIData: [],
    }
  },
  computed: {
    locale() {
      return (this.uiSettings?.dates ?? 'DE_DE').replace('_', '-')
    },
    queryParams() {
      const { query } = this.$route
      const fleet = query?.fleet || ''
      const shift = query?.shift || ''
      const shiftOccurrance = query?.shiftOccurrance || ''
      const daysFromNow = query?.daysFromNow || ''

      return `${fleet}${shift}${shiftOccurrance}${daysFromNow}`
    },
    canLoadAssets() {
      return this.interval && (this.assetIds.length ?? 0) > 0
    },
    assetsMapped() {
      return this.assetsSeveritiesList?.map(assetWithServity => {
        const selectedAsset = this.assetsKPIData.find(asset => asset.id === assetWithServity.assetId)
        let isAssetWithoutIssues = false
        let isAssetWithoutData = false
        const issueCounts = {
          priorityCount: {
            count: 0,
            isActive: false,
          },
          errorCount: {
            count: 0,
            isActive: false,
          },
          warningCount: {
            count: 0,
            isActive: false,
          },
          malfunctionCount: {
            count: 0,
            isActive: false,
          },
          protectionCount: {
            count: 0,
            isActive: false,
          },
        }
        assetWithServity?.severities?.forEach(severity => {
          if (severity.severity === 'Green') {
            isAssetWithoutIssues = true
          } else if (severity.severity === 'NoData') {
            isAssetWithoutData = true
          } else {
            issueCounts[this.severityCountMapping[severity.severity]] = {
              count: severity?.count,
              isActive: severity?.isActive,
            }
          }
        })
        return {
          ...selectedAsset,
          name: assetWithServity?.asset.name,
          type: assetWithServity?.asset.type,
          operatingTimeLabel: this.label,
          isAssetWithoutIssues,
          isAssetWithoutData,
          severities: issueCounts,
        }
      }, [])
    },
    severityRanking() {
      return {
        PRIORITY: 6,
        ERROR: 5,
        WARNING: 4,
        MALFUNCTION: 3,
        PROTECTION: 2,
        OK: 1,
      }
    },
    severityMapping() {
      return {
        priority: 'PRIORITY',
        error: 'ERROR',
        warning: 'WARNING',
        malfunction: 'MALFUNCTION',
        protection: 'PROTECTION',
        green: 'OK',
      }
    },
    severityCountMapping() {
      return {
        Priority: 'priorityCount',
        Red: 'errorCount',
        Yellow: 'warningCount',
        Malfunction: 'malfunctionCount',
        Protection: 'protectionCount',
      }
    },
    severityIds() {
      return ['all', 'priority', 'error', 'warning', 'malfunction', 'protection', 'green']
    },
    label() {
      const kpiLocale = this.locale.slice(0, 2)
      return this.assetDimensions[0]?.nameTranslations
        ? getAssetDimensionNameByLocale(this.assetDimensions[0].nameTranslations, kpiLocale)
        : this.assetDimensions?.name
    },
    userLocaleForQuery() {
      return this.locale.slice(0, 2).toLowerCase() + this.locale.slice(2, 5)
    },
    isLoading() {
      return this.isFirstPage || this.isParentLoading
    },
    isFirstPage() {
      return this.offset === 0 && this.$apollo.queries.assetSeveritiesCount.loading
    },
    hasMoreRows() {
      const currentPageEndIndex = this.offset + this.limit
      return currentPageEndIndex < this.assetIds.length
    },
    shouldShowLoadMoreButton() {
      return !this.isFirstPage && this.hasMoreRows && !this.isLoading
    },
  },
  methods: {
    reset() {
      this.limit = 50
      this.offset = 0
      this.loading = true
    },
    async loadMoreAssets() {
      this.offset += this.limit
    },
  },
  apollo: {
    assetSeveritiesCount: {
      query: ASSET_SEVERITIES_COUNT_QUERY,
      variables() {
        return {
          where: {
            interval: this.interval,
            assetIds: this.assetIds,
            locale: this.userLocaleForQuery,
            limit: this.limit,
            offset: this.offset,
          },
        }
      },
      skip() {
        return !this.canLoadAssets
      },
      result({ data }) {
        if (this.offset === 0) {
          // offset is 0 that means Severity has changed and this is the time we have to reset the list
          // otherwise previous severity data will be appended to the severity list
          this.assetsSeveritiesList = []
        }
        this.assetsSeveritiesList = [...this.assetsSeveritiesList, ...data.assetSeveritiesCount]
        this.currentOffsetAssetIds = data.assetSeveritiesCount?.map(asset => asset.assetId)
      },
    },
    assets: {
      query: ASSETS_WITH_KPI_AND_LAST_POSITION,
      variables() {
        return {
          interval: this.interval,
          assetIds: this.currentOffsetAssetIds,
          locale: this.userLocaleForQuery,
        }
      },
      skip() {
        return this.currentOffsetAssetIds.length < 1
      },
      result({ data }) {
        if (this.offset === 0) {
          // offset is 0 that means Severity has changed and this is the time we have to reset the list
          // otherwise previous severity data will be appended to the severity list
          this.assetsKPIData = []
        }
        this.assetsKPIData = [...this.assetsKPIData, ...data.assets]
        this.currentOffsetAssetIds = []
      },
    },
    assetDimensions: {
      query: ASSET_OPERATING_TIME_TRANSLATION,
      variables() {
        return {
          where: {
            name: 'operation_time',
          },
        }
      },
    },
  },
}
</script>
