var render = function () {
  var _vm$filter$value$labe, _vm$filter$value;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('FilterValueAtomStyled', {
    class: {
      muted: _vm.filter.isDisabled
    }
  }, [_vm.isLoading ? _c('LoadingDots') : _c('span', {
    staticClass: "value"
  }, [_vm._v(_vm._s((_vm$filter$value$labe = (_vm$filter$value = _vm.filter.value) === null || _vm$filter$value === void 0 ? void 0 : _vm$filter$value.label) !== null && _vm$filter$value$labe !== void 0 ? _vm$filter$value$labe : '-'))])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }