<template>
  <HealthExplorerAssetStyled>
    <div class="summary" @click="isExpanded = !isExpanded">
      <div class="column toggle">
        <ChevronRightIcon class="icon" :class="{ rotate: isExpanded }" />
      </div>
      <AssetNameMolecule class="column name" :asset="asset" />
      <AssetIssueCountsMolecule
        class="column count"
        :severities="asset.severities"
        :isGreenEnabledWithOperatingTime="asset.isAssetWithoutIssues"
      />
      <div class="column coverage" v-if="!isSmall">
        <KeyValueAtom v-for="item in coverageItems" :class="item.id" :key="item.id" :keyString="item.label" :valueString="item.value" />
      </div>
    </div>
    <AssetIssuesGroupedMolecule :assetId="asset.id" :interval="interval" v-if="isExpanded" />
  </HealthExplorerAssetStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import { ChevronRightIcon } from 'vue-feather-icons'
import chroma from 'chroma-js'

import units from '@/utils/units'
import localesMixin from '@/mixins/locales'
import { isNil } from 'lodash'

import KeyValueAtom from '@/components/Atomic/Atoms/KeyValueAtom'
import AssetNameMolecule from './AssetMolecule/AssetNameMolecule'
import AssetIssueCountsMolecule from './AssetMolecule/AssetIssueCountsMolecule'
import AssetIssuesGroupedMolecule from './AssetMolecule/AssetIssuesGroupedMolecule'

const HealthExplorerAssetStyled = styled('div')`
  &:first-child {
    .summary {
      border-top: none !important;
    }
  }
  .summary {
    cursor: pointer;
    display: grid;
    user-select: none;
    grid-template-columns: 2rem 380px 420px 1fr;
    grid-template-areas: 'toggle name count coverage';
    grid-template-rows: 1fr;
    background-color: ${({ theme }) => theme.colors.widgetBG};
    border-top: 1px solid ${({ theme }) => theme.colors.mainBG};
    > .column {
      padding: 0.5rem;
    }
    > .toggle {
      grid-area: toggle;
      display: flex;
      align-items: center;
      color: ${({ theme }) => theme.colors.primary};
      .feather.rotate {
        transform: rotate(90deg);
      }
    }
    > .name {
      grid-area: name;
    }
    > .count {
      grid-area: count;
    }
    > .coverage {
      display: none;
      grid-area: coverage;
      gap: 0.25rem;
      justify-content: space-evenly;
      grid-template-columns: 1fr;
      grid-template-rows: min-content min-content;
      font-size: 14px;

      @media (min-width: 896px) {
        display: grid;
      }
      @media (min-width: 1400px) {
        grid-template-columns: repeat(2, max-content);
        grid-template-rows: 1fr;
        gap: 1rem;
        font-size: 1rem;
        > div {
          grid-template-columns: 1fr 5rem;
          > span {
            justify-content: flex-start;
          }
        }
      }
    }

    &:hover {
      > .toggle {
        color: ${({ theme }) => theme.colors.primaryLighter};
      }
      background-color: ${({ theme }) =>
        theme.isDark ? chroma(theme.colors.solidBG).brighten(0.5).css() : chroma(theme.colors.widgetBG).darken(0.2).css()};
    }
  }
  @media (max-width: 1024px) {
    .summary {
      grid-template-columns: 2rem 1fr 1fr;
      grid-template-rows: 1fr;
      grid-template-areas: 'toggle name count';
    }
  }
  @media (max-width: 768px) {
    .summary {
      grid-template-columns: 2rem 1fr;
      grid-template-rows: 1fr;
      grid-template-areas:
        'toggle name'
        'toggle count';
    }
  }
  @media (max-width: 425px) {
    justify-items: stretch;
  }
`

export default {
  mixins: [localesMixin],
  components: {
    HealthExplorerAssetStyled,
    ChevronRightIcon,
    AssetIssuesGroupedMolecule,
    AssetNameMolecule,
    AssetIssueCountsMolecule,
    KeyValueAtom,
  },
  props: {
    asset: {
      type: Object,
      required: true,
    },
    interval: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      screenWidth: window.innerWidth,
      isExpanded: false,
    }
  },
  watch: {
    issueCounts: {
      handler() {
        this.isExpanded = false
      },
    },
  },
  computed: {
    isSmall() {
      return this.screenWidth < 1025
    },
    issueCounts() {
      return this.asset?.issueCounts
    },
    activeIssues() {
      return this.asset.activeIssueSeverities
    },
    coverageItems() {
      const lastTotalEngineHours = this.asset?.lastPosition?.totalMachineHours
      const lastTotalEngineHoursFormatted = lastTotalEngineHours
        ? units(lastTotalEngineHours, 'h', 'h', 2, true, true, false, this.thousandsSeperator, this.decimalSeperator, true)
        : '-'

      const operatingTime = this.asset?.kpiData?.[0]
      const operatingTimeFormatted = operatingTime
        ? units(operatingTime, 's', 'h', 2, true, true, false, this.thousandsSeperator, this.decimalSeperator, true)
        : '-'

      return [
        { id: 'operation', label: this.asset.operatingTimeLabel, value: operatingTimeFormatted },
        { id: 'engineHours', label: this.$t('totalMachineHours'), value: lastTotalEngineHoursFormatted },
      ]
    },
    hasAssetBeenOperated() {
      return !isNil(this.asset?.kpiData?.[0])
    },
  },
  methods: {
    onResize() {
      this.screenWidth = window.innerWidth
    },
  },
  created() {
    window.addEventListener('resize', this.onResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize)
  },
}
</script>
