var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetNameMoleculeStyled', [_vm.asset && _vm.asset.name === undefined ? _c('div', [_c('LoadingDots')], 1) : _vm._e(), _c('div', {
    staticClass: "icon"
  }, [_c('TalpaIcon', {
    staticClass: "asset-icon",
    attrs: {
      "scope": 'AssetTypes',
      "name": _vm.iconName
    }
  })], 1), _c('div', {
    staticClass: "name"
  }, [_vm._v(" " + _vm._s(_vm.asset.name) + " ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }