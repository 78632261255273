<template>
  <AssetIssueStyled>
    <div class="information">
      <div class="start">
        <span class="muted">{{ $tc('start') | capitalize }}:</span>
        <span>{{ start }}</span>
      </div>
      <div class="end">
        <span class="muted">{{ $tc('end') | capitalize }}:</span>
        <span>{{ end }}</span>
      </div>
      <div class="duration">
        <span class="muted">{{ $tc('duration') | capitalize }}:</span>
        <span>{{ duration }}</span>
      </div>
    </div>
    <div class="actions">
      <GoToAtom :to="to" :label="label" />
    </div>
  </AssetIssueStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { DateTime, Duration } from 'luxon'
import { VTooltip } from 'v-tooltip'
import GoToAtom from '@/components/Atomic/Atoms/GoToAtom.vue'

const AssetIssueStyled = styled('div')`
  display: grid;
  grid-template-columns: max-content auto;
  grid-template-rows: min-content;
  grid-template-areas: 'information action';
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.colors.panelBG};
  align-items: center;
  .information {
    display: grid;
    grid-area: information;
    grid-template-columns: max-content max-content;
    grid-template-rows: min-content;
    grid-template-areas: 'start end duration';
    grid-gap: 1.5rem;
    > div {
      .muted {
        padding-right: 0.3rem;
      }
    }
    strong {
      margin-left: 0.5rem;
    }
    .start {
      grid-area: start;
    }
    .end {
      grid-area: end;
    }
    .duration {
      grid-area: duration;
    }
    .message {
      grid-area: message;
    }
  }
  .actions {
    display: grid;
    grid-area: action;
    grid-template-columns: 1fr;
    gap: 0.5rem;
    justify-items: end;
    > button {
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 12rem;
      padding: 0.5rem;
      border-radius: 0.5rem;
      color: ${p => p.theme.colors.white};
      background: ${({ theme }) => theme.colors.primary};
      > .feather {
        margin-right: 0.5rem;
      }
      > strong {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &:hover {
        color: ${p => p.theme.colors.primaryLighter};
      }
      &:disabled {
        opacity: 0.8;
        color: ${p => p.theme.colors.muted};
      }
    }
  }
  @media (max-width: 1024px) {
    .information {
      grid-template-columns: 1fr;
      grid-template-rows: min-content;
      grid-template-areas:
        'start'
        'end'
        'duration';
    }
  }
`

export default {
  inject: ['uiSettings'],
  props: {
    issue: {
      type: Object,
      required: true,
    },
    asset: {
      type: Object,
      required: true,
    },
  },
  components: {
    AssetIssueStyled,
    GoToAtom,
  },
  directives: {
    tooltip: VTooltip,
  },
  data() {
    return {}
  },
  computed: {
    locale() {
      return (this.uiSettings?.dates ?? 'DE_DE').toLowerCase().replace('_', '-')
    },
    start() {
      return DateTime.fromISO(this.issue.start).setLocale(this.locale).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
    },
    end() {
      return this.issue.end
        ? DateTime.fromISO(this.issue.end).setLocale(this.locale).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
        : '-'
    },
    utcStart() {
      return DateTime.fromISO(this.issue.start).setZone('UTC').setLocale(this.locale).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
    },
    duration() {
      return Duration.fromMillis(this.issue.duration * 1000)
        .shiftTo('minutes', 'seconds')
        .normalize()
        .toHuman({ unitDisplay: 'short' })
    },
    routeName() {
      return this.$route.meta?.isSDApp ? 'ServiceDealerAssetHealthInsight' : 'AssetHealthInsight'
    },
    to() {
      const assetHealthInsightRoute = {
        name: this.routeName,
        params: {
          id: this.asset?.id,
        },
      }
      if (this.issue?.id) {
        assetHealthInsightRoute.query = {
          issue: this.issue?.id,
        }
      }
      return assetHealthInsightRoute
    },
    label() {
      return this.$tc('actions.goToInsights')
    },
  },
  methods: {
    goToInsights() {},
  },
}
</script>
