<template>
  <MaintenancePanelMolecule :title="$t('maintenance.summaryByAssetType')">
    <template v-slot:main>
      <TalpaLoaderWrapper v-if="isLoading" />
      <SummaryByAssetTypeMoleculeStyled v-else>
        <FleetHealthCompositionMolecule :fleetComposition="fleetComposition" />
        <ActiveSeverityPieChartMolecule :fleetComposition="fleetComposition" />
      </SummaryByAssetTypeMoleculeStyled>
    </template>
  </MaintenancePanelMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { TalpaLoaderWrapper } from '@common/components'

import MaintenancePanelMolecule from '@/components/Atomic/Molecules/Maintenance/MaintenancePanelMolecule'
// import MachineTypeHealthCompositionMoleculeV2 from './SummaryByAssetTypeMolecule/MachineTypeHealthCompositionMoleculeV2'
import FleetHealthCompositionMolecule from './SummaryByAssetTypeMolecule/FleetHealthCompositionMolecule'
import ActiveSeverityPieChartMolecule from './SummaryByAssetTypeMolecule/ActiveSeverityPieChartMolecule'

const SummaryByAssetTypeMoleculeStyled = styled('div')`
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content;
  justify-items: center;
  align-items: center;
  @media (min-width: 1024px) {
    grid-template-columns: min-content 1fr;
  }
`

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    fleetComposition: {
      type: Map,
      required: true,
    },
  },
  components: {
    TalpaLoaderWrapper,
    MaintenancePanelMolecule,
    FleetHealthCompositionMolecule,
    ActiveSeverityPieChartMolecule,
    SummaryByAssetTypeMoleculeStyled,
  },
}
</script>
