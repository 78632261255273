var render = function () {
  var _vm$severityData$get, _vm$severityData, _vm$severityData2, _vm$severityData$get2, _vm$severityData3, _vm$severityData4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SeverityLampsMoleculeV2Styled', [_vm._l(_vm.severityData, function (_ref) {
    var severityEnum = _ref[0],
      count = _ref[1];
    return [severityEnum !== 'OK' && severityEnum !== 'NODATA' ? _c('SeverityLampAtomV2', {
      key: severityEnum,
      attrs: {
        "severityEnum": severityEnum,
        "active": count > 0,
        "count": count,
        "showCount": _vm.showCounts
      }
    }) : _vm._e()];
  }), _vm.showOkLamp ? _c('SeverityLampAtomV2', {
    attrs: {
      "severityEnum": 'OK',
      "active": ((_vm$severityData$get = (_vm$severityData = _vm.severityData) === null || _vm$severityData === void 0 ? void 0 : _vm$severityData.get('OK')) !== null && _vm$severityData$get !== void 0 ? _vm$severityData$get : 0) > 0,
      "count": (_vm$severityData2 = _vm.severityData) === null || _vm$severityData2 === void 0 ? void 0 : _vm$severityData2.get('OK'),
      "showCount": _vm.showCounts
    }
  }) : _vm._e(), _vm.showNoData ? _c('SeverityLampAtomV2', {
    attrs: {
      "severityEnum": 'NODATA',
      "active": ((_vm$severityData$get2 = (_vm$severityData3 = _vm.severityData) === null || _vm$severityData3 === void 0 ? void 0 : _vm$severityData3.get('NODATA')) !== null && _vm$severityData$get2 !== void 0 ? _vm$severityData$get2 : 0) > 0,
      "count": (_vm$severityData4 = _vm.severityData) === null || _vm$severityData4 === void 0 ? void 0 : _vm$severityData4.get('NODATA'),
      "showCount": _vm.showCounts
    }
  }) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }