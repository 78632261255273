<template>
  <ActiveSeverityPieChartMoleculeStyled>
    <PieChart :option="option" />
  </ActiveSeverityPieChartMoleculeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import PieChart from '@/components/Atomic/Atoms/PieChart'
import { BaseColors } from '@styles/themes'

const ActiveSeverityPieChartMoleculeStyled = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 260px;
`

export default {
  inject: ['theme'],
  props: {
    fleetComposition: {
      type: Map,
      required: true,
    },
  },
  components: {
    ActiveSeverityPieChartMoleculeStyled,
    PieChart,
  },
  computed: {
    colors() {
      return [
        BaseColors.atomic.severityPriority,
        BaseColors.atomic.severityRed,
        BaseColors.atomic.severityAmber,
        BaseColors.atomic.severityMalfunction,
        BaseColors.atomic.severityProtection,
        BaseColors.atomic.statusGreen,
        BaseColors.atomic.severityNoData,
      ]
    },
    piechartRadius() {
      const radius = {
        radius: ['40%', '90%'],
        center: ['50%', '50%'],
      }
      return radius
    },
    fleetTotal() {
      let count = 0
      for (const item of this.fleetComposition?.get('_all')) {
        count += item[1]
      }
      return count
    },
    dataSets() {
      return Array.from(this.fleetComposition?.get('_all')).map(item => {
        return {
          name: this.$t(`severityCodes.${item[0]?.toLowerCase()}`),
          value: item[1],
          tooltipValue: item[1],
        }
      })
    },
    option() {
      return {
        tooltip: {
          trigger: 'item',
        },
        legend: {
          show: false,
          textStyle: {},
        },
        series: [
          {
            name: this.$tc('severity', 2),
            type: 'pie',
            ...this.piechartRadius,
            data: this.dataSets,
            itemStyle: {
              borderWidth: 2,
              borderColor: this.theme.colors.solidBG,
            },
            label: {
              show: true,
              position: 'center',
              fontStyle: 'bold',
              fontSize: 24,
              color: this.theme.isDark ? this.theme.colors.muted : this.theme.colors.dimGrey,
              formatter: () => {
                return this.fleetTotal
              },
            },
            avoidLabelOverlap: false,
            color: this.colors,
            animation: true,
            animationType: 'scale',
            animationEasing: 'elasticOut',
          },
        ],
      }
    },
  },
}
</script>
