var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ClickableCardsMolecule', {
    attrs: {
      "cards": _vm.cards
    },
    on: {
      "set-selected-card": function setSelectedCard($event) {
        return _vm.$emit('set-selected-card', $event);
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }