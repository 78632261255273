<template>
  <AssetTypeSeverityCountsMoleculeStyled>
    <div class="icon">
      <TalpaIcon v-if="hasIcon" class="asset-icon" :scope="'AssetTypes'" :name="icon" />
    </div>
    <span class="label">
      <strong>{{ label }}:</strong> {{ count }}
    </span>
    <SeverityLampsMoleculeV2 :severityData="assetTypeData" :showCounts="true" :showOkLamp="true" :showNoData="true" />
  </AssetTypeSeverityCountsMoleculeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { TalpaIcon } from '@common/components'

import SeverityLampsMoleculeV2 from '@/components/Atomic/Molecules/SeverityLampsMoleculeV2'

const AssetTypeSeverityCountsMoleculeStyled = styled('div')`
  display: grid;
  grid-template-columns: 2.5rem 6rem 1fr;
  grid-template-rows: 2rem;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => (theme.isDark ? theme.colors.panelBG : theme.colors.whiteSmokeDark)};
  border-radius: 0.5rem;
`

export default {
  props: {
    assetType: {
      type: String,
      required: true,
    },
    assetTypeData: {
      type: Map,
      required: true,
    },
  },
  components: {
    AssetTypeSeverityCountsMoleculeStyled,
    SeverityLampsMoleculeV2,
    TalpaIcon,
  },
  computed: {
    count() {
      let total = 0
      for (const item of this.assetTypeData) {
        total += item[1]
      }
      return total
    },
    hasIcon() {
      return this.assetType !== '_all'
    },
    icon() {
      return this.assetType?.split(' ').join('') ?? ''
    },
    label() {
      return this.assetType === '_all' ? this.$tc('maintenance.fleet', 1) : this.$tc('total', 1)
    },
  },
}
</script>
