var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetTypeSeverityCountsMoleculeStyled', [_c('div', {
    staticClass: "icon"
  }, [_vm.hasIcon ? _c('TalpaIcon', {
    staticClass: "asset-icon",
    attrs: {
      "scope": 'AssetTypes',
      "name": _vm.icon
    }
  }) : _vm._e()], 1), _c('span', {
    staticClass: "label"
  }, [_c('strong', [_vm._v(_vm._s(_vm.label) + ":")]), _vm._v(" " + _vm._s(_vm.count) + " ")]), _c('SeverityLampsMoleculeV2', {
    attrs: {
      "severityData": _vm.assetTypeData,
      "showCounts": true,
      "showOkLamp": true,
      "showNoData": true
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }