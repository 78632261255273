<template>
  <NoDataAtomStyled :class="{ 'margin-v': marginV }">{{ $t('messages.noDataAvailable') }}</NoDataAtomStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const NoDataAtomStyled = styled('div')`
  &.margin-v {
    margin: 0 1rem;
  }
`

export default {
  props: {
    marginV: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    NoDataAtomStyled,
  },
}
</script>
