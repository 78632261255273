var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MaintenancePanelMolecule', {
    attrs: {
      "title": _vm.$t('maintenance.summaryByAssetType')
    },
    scopedSlots: _vm._u([{
      key: "main",
      fn: function fn() {
        return [_vm.isLoading ? _c('TalpaLoaderWrapper') : _c('SummaryByAssetTypeMoleculeStyled', [_c('FleetHealthCompositionMolecule', {
          attrs: {
            "fleetComposition": _vm.fleetComposition
          }
        }), _c('ActiveSeverityPieChartMolecule', {
          attrs: {
            "fleetComposition": _vm.fleetComposition
          }
        })], 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }