var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('HeaderMolecule', {
    attrs: {
      "isLoading": _vm.isLoading,
      "filters": _vm.filters,
      "iconName": _vm.iconName
    },
    on: {
      "select": function select($event) {
        return _vm.$emit('set-filter', $event);
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }