<template>
  <FleetHealthCompositionMoleculeStyled>
    <template v-for="[assetType, assetTypeData] of fleetComposition">
      <AssetTypeSeverityCountsMolecule v-if="assetType !== '_all'" :key="assetType" :assetType="assetType" :assetTypeData="assetTypeData" />
    </template>
    <AssetTypeSeverityCountsMolecule :assetType="'_all'" :assetTypeData="fleetComposition.get('_all')" />
  </FleetHealthCompositionMoleculeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import AssetTypeSeverityCountsMolecule from './FleetHealthCompositionMolecule/AssetTypeSeverityCountsMolecule'

const FleetHealthCompositionMoleculeStyled = styled('div')`
  padding: 1rem;
  display: grid;
  grid-template-column: 1fr;
  grid-auto-rows: min-content;
  grid-gap: 0.5rem;
`

export default {
  props: {
    fleetComposition: {
      type: Map,
      required: true,
    },
  },
  components: {
    FleetHealthCompositionMoleculeStyled,
    AssetTypeSeverityCountsMolecule,
  },
}
</script>
