var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('HealthExplorerStyled', [_c('HeaderOrganism', {
    staticClass: "header",
    attrs: {
      "isLoading": _vm.$apollo.loading,
      "selectedSubsidiaryId": _vm.selectedSubsidiaryId,
      "selectedShiftId": _vm.selectedShiftId,
      "selectedShiftOccurranceId": _vm.selectedShiftOccurranceId,
      "selectedDayId": _vm.selectedDayId
    },
    on: {
      "set-filter": _vm.setRouteQuery
    }
  }), _c('div', {
    staticClass: "group"
  }, [_c('ClickableCardsOrganism', {
    staticClass: "cards",
    attrs: {
      "cards": _vm.cards,
      "selectedSeverityId": _vm.selectedSeverityId,
      "defaultCardId": _vm.defaultCardId
    },
    on: {
      "set-selected-card": function setSelectedCard($event) {
        return _vm.setRouteQuery({
          filter: 'severity',
          id: $event
        });
      }
    }
  }), _c('AssetListOrganism', {
    staticClass: "list",
    attrs: {
      "interval": _vm.interval,
      "assetIds": _vm.selectedAssetIds,
      "selectedSeverityId": _vm.selectedSeverityId,
      "isParentLoading": _vm.$apollo.loading
    }
  })], 1), _c('SummaryByAssetTypeOrganism', {
    attrs: {
      "isLoading": _vm.$apollo.loading,
      "assetsBySeverity": _vm.assetsBySeverity
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }