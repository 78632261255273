<template>
  <ClickableCardsMolecule :cards="cards" @set-selected-card="$emit('set-selected-card', $event)" />
</template>

<script>
import ClickableCardsMolecule from '@/components/Atomic/Molecules/ClickableCardsMolecule'

export default {
  inject: ['uiSettings'],
  props: {
    selectedSeverityId: {
      type: String,
      required: true,
    },
    defaultCardId: {
      type: String,
      required: true,
    },
    cards: {
      type: Array,
      required: true,
    },
  },
  components: {
    ClickableCardsMolecule,
  },
  computed: {
    locale() {
      return (this.uiSettings?.dates ?? 'DE_DE').replace('_', '-')
    },
    userLocaleForQuery() {
      return this.locale.slice(0, 2).toLowerCase() + this.locale.slice(2, 5)
    },
  },
}
</script>
