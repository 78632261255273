var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetIssueCountsStyled', [_c('SeverityCountStyled', [_c('SeverityLampAtom', {
    class: {
      priority: _vm.severities.priorityCount.count > 0,
      active: _vm.severities.priorityCount.isActive
    }
  }), _c('strong', [_vm._v(_vm._s(_vm.severities.priorityCount.count))])], 1), _c('SeverityCountStyled', [_c('SeverityLampAtom', {
    class: {
      red: _vm.severities.errorCount.count > 0,
      active: _vm.severities.errorCount.isActive
    }
  }), _c('strong', [_vm._v(_vm._s(_vm.severities.errorCount.count))])], 1), _c('SeverityCountStyled', [_c('SeverityLampAtom', {
    class: {
      amber: _vm.severities.warningCount.count > 0,
      active: _vm.severities.warningCount.isActive
    }
  }), _c('strong', [_vm._v(_vm._s(_vm.severities.warningCount.count))])], 1), _c('SeverityCountStyled', [_c('SeverityLampAtom', {
    class: {
      malfunction: _vm.severities.malfunctionCount.count > 0,
      active: _vm.severities.malfunctionCount.isActive
    }
  }), _c('strong', [_vm._v(_vm._s(_vm.severities.malfunctionCount.count))])], 1), _c('SeverityCountStyled', [_c('SeverityLampAtom', {
    class: {
      protection: _vm.severities.protectionCount.count > 0,
      active: _vm.severities.protectionCount.isActive
    }
  }), _c('strong', [_vm._v(_vm._s(_vm.severities.protectionCount.count))])], 1), _c('SeverityCountStyled', [_c('SeverityLampAtom', {
    class: {
      green: _vm.isGreenEnabledWithOperatingTime
    },
    scopedSlots: _vm._u([{
      key: "icon",
      fn: function fn() {
        return [_c('CheckIcon')];
      },
      proxy: true
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }