var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('FleetHealthCompositionMoleculeStyled', [_vm._l(_vm.fleetComposition, function (_ref) {
    var assetType = _ref[0],
      assetTypeData = _ref[1];
    return [assetType !== '_all' ? _c('AssetTypeSeverityCountsMolecule', {
      key: assetType,
      attrs: {
        "assetType": assetType,
        "assetTypeData": assetTypeData
      }
    }) : _vm._e()];
  }), _c('AssetTypeSeverityCountsMolecule', {
    attrs: {
      "assetType": '_all',
      "assetTypeData": _vm.fleetComposition.get('_all')
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }