var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('HealthExplorerAssetStyled', [_c('div', {
    staticClass: "summary",
    on: {
      "click": function click($event) {
        _vm.isExpanded = !_vm.isExpanded;
      }
    }
  }, [_c('div', {
    staticClass: "column toggle"
  }, [_c('ChevronRightIcon', {
    staticClass: "icon",
    class: {
      rotate: _vm.isExpanded
    }
  })], 1), _c('AssetNameMolecule', {
    staticClass: "column name",
    attrs: {
      "asset": _vm.asset
    }
  }), _c('AssetIssueCountsMolecule', {
    staticClass: "column count",
    attrs: {
      "severities": _vm.asset.severities,
      "isGreenEnabledWithOperatingTime": _vm.asset.isAssetWithoutIssues
    }
  }), !_vm.isSmall ? _c('div', {
    staticClass: "column coverage"
  }, _vm._l(_vm.coverageItems, function (item) {
    return _c('KeyValueAtom', {
      key: item.id,
      class: item.id,
      attrs: {
        "keyString": item.label,
        "valueString": item.value
      }
    });
  }), 1) : _vm._e()], 1), _vm.isExpanded ? _c('AssetIssuesGroupedMolecule', {
    attrs: {
      "assetId": _vm.asset.id,
      "interval": _vm.interval
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }