<template>
  <SeverityLampWithCountStyled>
    <SeverityLampAtomV2Styled :class="[classes]">
      <CheckIcon v-if="severityEnum && severityEnum === 'OK'" />
      <XIcon v-if="severityEnum && severityEnum === 'NODATA'" />
    </SeverityLampAtomV2Styled>
    <span v-if="showCount" class="count">
      {{ count }}
    </span>
  </SeverityLampWithCountStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { CheckIcon, XIcon } from 'vue-feather-icons'

const SeverityLampWithCountStyled = styled('span')`
  display: flex;
  align-items: center;
  .count {
    margin-left: 0.5rem;
    min-width: 2rem;
    font-weight: bold;
  }
`

const SeverityLampAtomV2Styled = styled('span')`
  display: inline-block;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: ${({ theme }) => theme.colors.atomic.severityLampOff};
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  &.active {
    &.priority {
      background: ${({ theme }) => theme.colors.atomic.severityPriority};
    }
    &.error {
      background: ${({ theme }) => theme.colors.atomic.severityRed};
    }
    &.warning {
      background: ${({ theme }) => theme.colors.atomic.severityAmber};
    }
    &.malfunction {
      background: ${({ theme }) => theme.colors.atomic.severityMalfunction};
    }
    &.protection {
      background: ${({ theme }) => theme.colors.atomic.severityProtection};
    }
    &.ok {
      background: ${({ theme }) => theme.colors.atomic.statusGreen};
    }
    &.nodata {
      background: ${({ theme }) => theme.colors.atomic.severityNoData};
    }
  }
  .feather {
    margin-top: 3px;
    margin-left: 3px;
    width: 14px;
    height: 14px;
    color: ${({ theme }) => theme.colors.atomic.iconBG};
  }
  &.active {
    .feather {
      color: ${({ theme }) => theme.colors.atomic.white};
    }
  }
`

export default {
  props: {
    severityEnum: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    count: {
      type: Number,
    },
    showCount: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SeverityLampWithCountStyled,
    SeverityLampAtomV2Styled,
    CheckIcon,
    XIcon,
  },
  computed: {
    classes() {
      return [this.severityEnum?.toLowerCase(), this.active ? 'active' : '']
    },
  },
}
</script>
