<template>
  <HeaderMoleculeStyled>
    <HeartIconAtom :iconName="iconName" />
    <TitleAtom />
    <FiltersMolecule :isLoading="isLoading" :filters="filters" @select="$emit('select', $event)" />
  </HeaderMoleculeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import FiltersMolecule from './HeaderMolecule/FiltersMolecule'
import TitleAtom from './HeaderMolecule/TitleAtom'
import HeartIconAtom from '@/components/Atomic/Atoms/HeartIconAtom'

const HeaderMoleculeStyled = styled('div')`
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-template-rows: 1fr;
  grid-gap: 1rem;
  align-items: center;
  background: ${({ theme }) => theme.colors.atomic.tableBG};
  border: 1px solid ${({ theme }) => theme.colors.mainBG};
  padding: 1rem;
  border-radius: 0.5rem;
`

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    filters: {
      type: Array,
      required: true,
    },
    iconName: {
      type: String,
      required: true,
    },
  },
  components: {
    HeaderMoleculeStyled,
    HeartIconAtom,
    TitleAtom,
    FiltersMolecule,
  },
}
</script>
