var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('FiltersMoleculeStyled', {
    class: {
      expanded: _vm.isExpanded
    }
  }, [_c('TransitionGroup', {
    staticClass: "animated-filters",
    class: {
      expanded: _vm.isExpanded
    },
    attrs: {
      "name": "animated-filters",
      "tag": "div"
    }
  }, [_vm._l(_vm.filtersMapped, function (filter) {
    return [!_vm.isExpanded || !filter.isDisabled ? _c('FilterLabelAtom', {
      key: filter.key + '_label',
      staticClass: "animated-filters-item label",
      attrs: {
        "isExpanded": _vm.isExpanded,
        "filter": filter
      }
    }) : _vm._e(), _vm.isExpanded && !filter.isDisabled && !_vm.isLoading ? _c('FilterDropdownAtom', {
      key: filter.key + '_dropdown',
      staticClass: "animated-filters-item dropdown",
      attrs: {
        "filter": filter
      },
      on: {
        "select": function select($event) {
          return _vm.$emit('select', {
            id: $event.id,
            filter: filter.id
          });
        }
      }
    }) : !_vm.isExpanded ? _c('FilterValueAtom', {
      key: filter.key + '_value',
      staticClass: "animated-filters-item value",
      attrs: {
        "isLoading": _vm.isLoading,
        "filter": filter
      }
    }) : _vm._e()];
  })], 2), !_vm.writeOnly ? _c('ButtonStyleless', {
    staticClass: "toggle",
    on: {
      "click": function click($event) {
        _vm.isExpanded = !_vm.isExpanded;
      }
    }
  }, [_c('TalpaIcon', {
    staticClass: "filter-icon",
    attrs: {
      "scope": 'Feed',
      "name": 'FilterIcon'
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }