<template>
  <HealthExplorerStyled>
    <HeaderOrganism
      class="header"
      :isLoading="$apollo.loading"
      :selectedSubsidiaryId="selectedSubsidiaryId"
      :selectedShiftId="selectedShiftId"
      :selectedShiftOccurranceId="selectedShiftOccurranceId"
      :selectedDayId="selectedDayId"
      @set-filter="setRouteQuery"
    />
    <div class="group">
      <ClickableCardsOrganism
        class="cards"
        :cards="cards"
        :selectedSeverityId="selectedSeverityId"
        :defaultCardId="defaultCardId"
        @set-selected-card="setRouteQuery({ filter: 'severity', id: $event })"
      />
      <AssetListOrganism
        class="list"
        :interval="interval"
        :assetIds="selectedAssetIds"
        :selectedSeverityId="selectedSeverityId"
        :isParentLoading="$apollo.loading"
      />
    </div>
    <SummaryByAssetTypeOrganism :isLoading="$apollo.loading" :assetsBySeverity="assetsBySeverity" />
  </HealthExplorerStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { Interval, DateTime } from 'luxon'
import { BaseColors } from '@styles/themes'

import { getLast7DaysInterval } from '../../utils/time'

import HeaderOrganism from '../SharedPages/HealthExplorer/HeaderOrganism'
import SummaryByAssetTypeOrganism from '../SharedPages/HealthExplorer/SummaryByAssetTypeOrganism'
import ClickableCardsOrganism from '../SharedPages/HealthExplorer/ClickableCardsOrganism'
import AssetListOrganism from '../SharedPages/HealthExplorer/AssetListOrganism'

import MY_SUBSIDIARIES_QUERY from '#/graphql/operations/maintenance/mySubsidiariesQuery.gql'
import ASSETS_BY_SEVERITY_QUERY from '#/graphql/operations/maintenance/assetsBySeverityQuery.gql'

const HealthExplorerStyled = styled('div')`
  display: grid;
  min-height: calc(100% - 3rem);
  padding: 4px;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(5.5rem, min-content) minmax(min-content, 1fr) min-content;
  grid-gap: 1rem;
  margin: 1rem;
  > .group {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content;
  }
`

export default {
  components: {
    HealthExplorerStyled,
    HeaderOrganism,
    ClickableCardsOrganism,
    AssetListOrganism,
    SummaryByAssetTypeOrganism,
  },
  data() {
    return {
      myCalendars: [],
      myMemberships: [],
      assetOperatingTimes: [],
      assetIssues: [],
      defaultCardId: 'all',
      mySubsidiaries: [],
      cardCounts: null,
      assetsBySeverity: [],
    }
  },
  computed: {
    selectedSeverityId() {
      return this.$route?.query?.severity ?? this.defaultCardId
    },
    selectedSubsidiaryId() {
      const byRoute = this.mySubsidiaries.find(f => f.id === this.$route?.query?.fleet)
      if (byRoute) {
        return byRoute.id
      }
      return this.mySubsidiaries?.[0]?.id ?? null
    },
    selectedSubsidiary() {
      return this.mySubsidiaries.find(f => f.id === this.selectedSubsidiaryId)
    },
    selectedShiftId() {
      // TODO:
      // check if byRoute.id is actually in
      // selectedSubsidiary.shifts.id
      const byRoute = this.mySubsidiaries.flatMap(subsidiary => subsidiary.shifts).find(f => f.id === this.$route?.query?.shift)
      if (byRoute) {
        return byRoute.id
      }
      const selectedSubsidiary = this.selectedSubsidiary
      if (selectedSubsidiary?.currentShift?.schedule?.id) {
        return selectedSubsidiary.currentShift.schedule.id
      } else if (selectedSubsidiary?.previousShift?.schedule?.id) {
        return selectedSubsidiary.previousShift.schedule.id
      } else {
        // should only happen when all the shifts for that subsidiary
        // are starting in the future or there is no subsidiaries at all
        return null
      }
    },
    selectedShift() {
      const shifts = this.selectedSubsidiary?.shifts ?? []
      return shifts.find(f => f.id === this.selectedShiftId)
    },
    selectedShiftOccurranceId() {
      // TODO:
      // check if byRoute.id is actually in
      // selectedShift.occurrances.id
      const byRoute = this.mySubsidiaries
        .flatMap(subsidiary => subsidiary.shifts)
        .flatMap(shift => shift.occurrences)
        .find(f => f.id === this.$route?.query?.shiftOccurrance)
      if (byRoute) {
        return byRoute.id
      }
      const selectedShift = this.selectedShift
      if (selectedShift?.currentShift?.id) {
        return selectedShift.currentShift.id
      } else if (selectedShift?.previousShift?.id) {
        return selectedShift.previousShift.id
      }
      // should only happen when all the shifts for that subsidiary
      // are starting in the future or there is no subsidiaries at all
      // or selected subsidiary has not shifts
      return null
    },
    selectedShiftOccurrance() {
      if (!this.selectedShiftOccurranceId) {
        return null
      }
      return this.mySubsidiaries
        .flatMap(subsidiary => subsidiary.shifts)
        .flatMap(shift => shift.occurrences)
        .find(f => f.id === this.selectedShiftOccurranceId)
    },
    selectedDayId() {
      return this.$route?.query?.daysFromNow ?? '0'
    },
    selectedDay() {
      const start = DateTime.local().minus({ days: this.selectedDayId }).startOf('day')
      const end = DateTime.local().minus({ days: this.selectedDayId }).endOf('day')
      return {
        interval: Interval.fromDateTimes(start, end),
        timezone: start.zoneName,
      }
    },
    interval() {
      return this.selectedShiftOccurrance?.interval ?? this.selectedDay?.interval.toISO()
    },
    timezone() {
      return this.selectedShiftOccurrance?.timezone ?? this.selectedDay?.timezone
    },
    assetIds() {
      const assets = this.selectedSubsidiary?.assets ?? []
      return assets.map(asset => asset.id)
    },
    translationMapping() {
      return {
        all: this.$t('all'),
        priority: this.$t('severityCodes.priority'),
        error: this.$t('severityCodes.red'),
        warning: this.$t('severityCodes.yellow'),
        malfunction: this.$t('severityCodes.malfunction'),
        protection: this.$t('severityCodes.protection'),
        green: this.$t('severityCodes.ok'),
        nodata: this.$t('noData'),
      }
    },
    colorMapping() {
      return {
        all: BaseColors.lightGrey,
        priority: BaseColors.atomic.severityPriority,
        error: BaseColors.atomic.severityRed,
        warning: BaseColors.atomic.severityAmber,
        malfunction: BaseColors.atomic.severityMalfunction,
        protection: BaseColors.atomic.severityProtection,
        green: BaseColors.atomic.statusGreen,
        nodata: BaseColors.atomic.severityNoData,
      }
    },
    countMapping() {
      return {
        Priority: 'priority',
        Red: 'error',
        Yellow: 'warning',
        Malfunction: 'malfunction',
        Protection: 'protection',
        Green: 'green',
        NoData: 'nodata',
      }
    },
    cardIds() {
      return ['all', 'priority', 'error', 'warning', 'malfunction', 'protection', 'green', 'nodata']
    },
    selectedAssetIds() {
      return this.cards.find(card => card.id === this.selectedSeverityId)?.assetIds ?? []
    },
    cards() {
      return this.cardIds.map(id => {
        const card = this?.cardCounts?.get(id) ?? 0
        return {
          id,
          label: this.$tc('asset', 2),
          badge: this.translationMapping[id],
          count: card.count || 0,
          assetIds: card.ids || [],
          isActive: this.selectedSeverityId === id,
          isDisabled: card.count < 1,
          isLoading: this.$apollo.loading,
          color: this.colorMapping[id],
          to: {
            query: {
              ...this.$route.query,
              severity: id === this.defaultCardId ? undefined : id,
            },
          },
        }
      })
    },
    timeframe() {
      const interval = Interval.fromISO(this.interval)
      return {
        start: interval.start.toISO(),
        end: interval.end.toISO(),
        timezone: this.timezone,
        granularity: 'PT1H',
      }
    },
  },
  methods: {
    /**
     * The query params set here for the ease of sharing the same filter state with another user.
     * The filter state is shared by the URL.
     */
    setRouteQuery({ filter, id }) {
      if (filter === 'severity') {
        this.$router.push({
          query: {
            ...this.$route.query,
            severity: id,
          },
        })
      } else if (filter === 'fleet') {
        this.$router.push({
          query: {
            severity: this.selectedSeverityId,
            fleet: id,
          },
        })
      } else if (filter === 'shift') {
        this.$router.push({
          query: {
            severity: this.selectedSeverityId,
            fleet: this.selectedSubsidiary?.id,
            shift: id,
          },
        })
      } else if (filter === 'shiftOccurrance') {
        this.$router.push({
          query: {
            severity: this.selectedSeverityId,
            fleet: this.selectedSubsidiary?.id,
            shift: this.selectedShift?.id,
            shiftOccurrance: id,
          },
        })
      } else if (filter === 'daysFromNow') {
        this.$router.push({
          query: {
            severity: this.selectedSeverityId,
            fleet: this.selectedSubsidiary?.id,
            daysFromNow: id,
          },
        })
      }
    },
  },
  apollo: {
    mySubsidiaries: {
      query: MY_SUBSIDIARIES_QUERY,
      variables() {
        const interval = getLast7DaysInterval()
        return {
          start: interval.start.toISO(),
          end: interval.end.toISO(),
        }
      },
    },
    assetsBySeverity: {
      query: ASSETS_BY_SEVERITY_QUERY,
      variables() {
        return {
          where: {
            interval: this.interval,
            assetIds: this.assetIds,
            locale: this.userLocaleForQuery,
          },
        }
      },
      skip() {
        return !this.interval || (this.assetIds.length ?? 0) < 1
      },
      manual: true,
      result({ data, error }) {
        if (error) {
          this.cardCounts = null
          return
        }

        this.assetsBySeverity = data?.assetsBySeverity
        const cardCounts = new Map(this.cardIds.map(id => [id, 0]))
        // allAssetIds is a set to avoid duplicates because
        // same assetId can be present in multiple severity
        let allAssetIds = new Set()
        this.assetsBySeverity?.forEach(({ severity, assetIds }) => {
          assetIds.forEach(assetId => allAssetIds.add(assetId))
          cardCounts.set(this.countMapping[severity], { count: assetIds.length, ids: assetIds })
        })
        const allAssetIdsArray = [...allAssetIds]
        cardCounts.set('all', { count: allAssetIdsArray.length, ids: allAssetIdsArray })
        this.cardCounts = cardCounts
      },
    },
  },
}
</script>
