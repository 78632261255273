<template>
  <HeaderMolecule :isLoading="isLoading" :filters="filters" :iconName="iconName" @select="$emit('set-filter', $event)" />
</template>

<script>
import HeaderMolecule from './HeaderMolecule'
import { DateTime, Interval } from 'luxon'
import { getLast7DaysInterval } from '../../../utils/time'

import MY_SUBSIDIARIES_QUERY from '#/graphql/operations/maintenance/mySubsidiariesQuery.gql'

export default {
  inject: ['uiSettings'],
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    selectedSubsidiaryId: {
      type: String,
    },
    selectedShiftId: {
      type: String,
    },
    selectedShiftOccurranceId: {
      type: String,
    },
    selectedDayId: {
      type: String,
    },
  },
  components: {
    HeaderMolecule,
  },
  data() {
    return {
      mySubsidiaries: [],
    }
  },
  computed: {
    locale() {
      return (this.uiSettings?.dates ?? 'DE_DE').toLowerCase().replace('_', '-')
    },
    iconName() {
      return this.$route.meta?.isSDApp ? 'ServiceDealerIcon' : 'MaintenanceIcon'
    },
    translationMap() {
      return {
        fleet: this.$tc('maintenance.fleet', 1),
        area: this.$tc('maintenance.area', 1),
        shift: this.$tc('maintenance.shiftPlan', 1),
        shiftOccurrance: this.$tc('time', 1),
        daysFromNow: this.$tc('time', 1),
      }
    },
    selectedSubisidaryHasShiftplans() {
      const selectedSubsidiary = this.mySubsidiaries.find(f => f.id === this.selectedSubsidiaryId)
      return (selectedSubsidiary?.shifts?.length ?? 0) > 0
    },
    optionsMap() {
      const map = new Map([
        ['fleet', []],
        ['area', []],
        ['shift', []],
        ['shiftOccurrance', []],
      ])
      const last7Days = [0, 1, 2, 3, 4, 5, 6].map(i => {
        const id = `${i}`
        return {
          id,
          label: DateTime.now().minus({ days: id }).setLocale(this.locale).toLocaleString(DateTime.DATE_MED),
          isSelected: id === this.selectedDayId,
        }
      })
      map.set('daysFromNow', last7Days)
      return this.mySubsidiaries.reduce((optionsMap, subsidiary) => {
        const isSelectedSubsidiary = subsidiary.id === this.selectedSubsidiaryId
        optionsMap.get('fleet').push({
          id: subsidiary.id,
          label: subsidiary.name,
          isSelected: isSelectedSubsidiary,
        })
        if (isSelectedSubsidiary) {
          subsidiary.shifts.forEach(shift => {
            const isSelectedShift = shift.id === this.selectedShiftId
            optionsMap.get('shift').push({
              id: shift.id,
              label: shift.name,
              isSelected: isSelectedShift,
            })
            if (isSelectedShift) {
              shift.occurrences.forEach(shiftOccurrance => {
                const isSelectedShiftOcccurrance = shiftOccurrance.id === this.selectedShiftOccurranceId
                const interval = Interval.fromISO(shiftOccurrance.interval)
                optionsMap.get('shiftOccurrance').push({
                  id: shiftOccurrance.id,
                  // label: Interval.fromISO(shiftOccurrance.interval).toLocaleString(),
                  label:
                    interval.start.setLocale(this.locale).toLocaleString(DateTime.DATETIME_MED) +
                    ' - ' +
                    interval.end.setLocale(this.locale).toLocaleString(DateTime.TIME_SIMPLE),
                  isSelected: isSelectedShiftOcccurrance,
                })
              })
            }
          })
        }
        return optionsMap
      }, map)
    },
    filters() {
      if (this.selectedSubisidaryHasShiftplans) {
        return ['fleet', 'area', 'shift', 'shiftOccurrance'].map(id => ({
          id,
          label: this.translationMap[id],
          value: this.optionsMap.get(id).find(f => f.isSelected) ?? null,
          isDisabled: id === 'area',
          options: this.optionsMap.get(id),
        }))
      }
      return ['fleet', 'area', 'shift', 'daysFromNow'].map(id => ({
        id,
        label: this.translationMap[id],
        value: this.optionsMap.get(id).find(f => f.isSelected) ?? null,
        isDisabled: id === 'area' || id === 'shift',
        options: this.optionsMap.get(id),
      }))
    },
  },
  apollo: {
    mySubsidiaries: {
      query: MY_SUBSIDIARIES_QUERY,
      variables() {
        const interval = getLast7DaysInterval()
        return {
          start: interval.start.toISO(),
          end: interval.end.toISO(),
        }
      },
    },
  },
}
</script>
