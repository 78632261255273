<template>
  <TitleAtomStyled>
    {{ title }}
  </TitleAtomStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const TitleAtomStyled = styled('span')`
  color: ${({ theme }) => theme.colors.atomic.textSecondary};
  line-height: 32px;
  font-size: 24px;
  font-weight: 400;
  text-transform: capitalize;
`

export default {
  components: {
    TitleAtomStyled,
  },
  computed: {
    title() {
      const isSDApp = this.$route.meta?.isSDApp
      return isSDApp ? this.$t('navigation.cockpit.ServiceDealerHealthExplorer') : this.$t('maintenance.HealthExplorer')
    },
  },
}
</script>
