var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$apollo.queries.assetIssues.loading ? _c('TalpaLoaderWrapper') : _c('AssetIssuesGroupedStyled', [_c('h4', [_vm._v(_vm._s(_vm.$t('maintenance.insights')) + ":")]), _vm.assetId ? _c('GoToAtom', {
    attrs: {
      "to": _vm.to,
      "label": _vm.label
    }
  }) : _vm._e(), _vm._l(_vm.groupedIssues, function (_ref) {
    var key = _ref[0],
      value = _ref[1];
    return [_c('ListWrapperStyled', {
      key: key + '_row',
      on: {
        "click": function click($event) {
          return _vm.toggleIssueTab(key);
        }
      }
    }, [_c('div', {
      staticClass: "action"
    }, [_c('ChevronRightIcon', {
      staticClass: "icon",
      class: {
        rotate: _vm.isDetailsExpanded && _vm.id === key
      }
    })], 1), _c('div', {
      staticClass: "count"
    }, [_c('CircleBadge', {
      staticClass: "filterButton",
      attrs: {
        "label": value.issues.length,
        "size": "M"
      }
    })], 1), _c('div', {
      staticClass: "code"
    }, [_c('strong', [_vm._v(_vm._s(_vm._f("capitalize")(_vm.$tc('code', 1))) + ":")]), _c('span', [_vm._v("#" + _vm._s(value.code))])]), _c('div', {
      staticClass: "severities"
    }, [_c('SeverityLampsMolecule', {
      attrs: {
        "severities": Array.from(value.severities),
        "activeIssues": value.activeIssues
      }
    })], 1), _c('div', {
      staticClass: "label"
    }, [_c('strong', [_vm._v(_vm._s(_vm._f("capitalize")(_vm.$tc('label', 1))) + ":")]), _c('span', [_vm._v(_vm._s(value.label))])]), _c('div', {
      staticClass: "duration"
    }, [_c('strong', [_vm._v(_vm._s(_vm._f("capitalize")(_vm.$tc('duration', 1))) + ":")]), _c('span', [_vm._v(" " + _vm._s(_vm._f("secondsTohhmmss")(value.duration)) + " ")])]), _c('div', {
      staticClass: "message"
    }, [_c('strong', [_vm._v(_vm._s(_vm._f("capitalize")(_vm.$tc('description', 1))) + ":")]), _c('span', [_vm._v(_vm._s(value.description))])])]), _vm.isDetailsExpanded && _vm.id === key ? _c('DetailsStyled', {
      key: key + '_details'
    }, _vm._l(value.issues, function (issue, i) {
      return _c('AssetIssueMolecule', {
        key: issue.id + '_subRow' + i,
        attrs: {
          "issue": issue,
          "asset": _vm.mappedAsset
        }
      });
    }), 1) : _vm._e()];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }