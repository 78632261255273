<template>
  <FiltersMoleculeStyled :class="{ expanded: isExpanded }">
    <TransitionGroup name="animated-filters" class="animated-filters" :class="{ expanded: isExpanded }" tag="div">
      <template v-for="filter in filtersMapped">
        <FilterLabelAtom
          v-if="!isExpanded || !filter.isDisabled"
          class="animated-filters-item label"
          :key="filter.key + '_label'"
          :isExpanded="isExpanded"
          :filter="filter"
        />
        <FilterDropdownAtom
          v-if="isExpanded && !filter.isDisabled && !isLoading"
          class="animated-filters-item dropdown"
          :key="filter.key + '_dropdown'"
          :filter="filter"
          @select="$emit('select', { id: $event.id, filter: filter.id })"
        />
        <FilterValueAtom
          v-else-if="!isExpanded"
          :isLoading="isLoading"
          class="animated-filters-item value"
          :key="filter.key + '_value'"
          :filter="filter"
        />
      </template>
    </TransitionGroup>
    <ButtonStyleless v-if="!writeOnly" class="toggle" @click="isExpanded = !isExpanded">
      <TalpaIcon class="filter-icon" :scope="'Feed'" :name="'FilterIcon'" />
    </ButtonStyleless>
  </FiltersMoleculeStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import { TalpaIcon } from '@common/components'

import ButtonStyleless from '@/components/Atomic/Atoms/ButtonStyleless'

import FilterLabelAtom from './FiltersMolecule/FilterLabelAtom'
import FilterDropdownAtom from './FiltersMolecule/FilterDropdownAtom'
import FilterValueAtom from './FiltersMolecule/FilterValueAtom'

const FiltersMoleculeStyled = styled('div')`
  position: relative;
  min-width: 17rem;
  display: grid;
  grid-template-columns: min-content min-content;
  grid-template-rows: 1fr;

  &.expanded {
    grid-template-columns: 1fr;
    @media (min-width: 896px) {
      grid-template-columns: min-content min-content;
    }
  }

  grid-gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  min-height: 2.5rem;
  box-shadow: ${({ theme }) => theme.colors.widgetShadow};
  background-color: ${({ theme }) =>
    theme.isDark ? chroma(theme.colors.solidBG).brighten(0.5).css() : chroma(theme.colors.widgetBG).darken(0.2).css()};

  .toggle {
    justify-self: end;
    width: 2rem;
    .filter-icon,
    .filter-icon > svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  .animated-filters {
    display: grid;
    grid-template-columns: repeat(4, max-content);
    grid-template-rows: min-content min-content;

    &.expanded {
      position: absolute;
      z-index: 1000;
      right: 0rem;
      top: 4rem;
      width: 17rem;
      padding: 0.5rem;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(8, max-content);
      box-shadow: ${({ theme }) => theme.colors.widgetShadow};
      border-radius: 0.5rem;
      background-color: ${({ theme }) =>
        theme.isDark ? chroma(theme.colors.solidBG).brighten(0.5).css() : chroma(theme.colors.widgetBG).darken(0.2).css()};
    }

    @media (min-width: 896px) {
      grid-template-columns: repeat(4, max-content);
      grid-template-rows: min-content min-content;
      border-radius: none;
      &.expanded {
        position: static;
        z-index: auto;
        right: auto;
        top: auto;
        width: auto;
        padding: 0;
        grid-template-columns: repeat(8, max-content);
        grid-template-rows: 2.5rem;
        box-shadow: none;
        border-radius: 0;
        background-color: transparent;
      }
    }

    grid-gap: 2px;
    align-items: center;
  }
  .animated-filters-item {
    transition: opacity 1s;
    display: inline-block;
  }
  .animated-filters-enter,
  .animated-filters-leave-to {
    opacity: 0;
  }
  .animated-filters-leave-to {
    transition: opacity 0s;
  }
  .animated-filters-leave-active {
    position: absolute;
  }
`

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    filters: {
      type: Array,
      required: true,
    },
    writeOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FiltersMoleculeStyled,
    ButtonStyleless,
    TalpaIcon,
    FilterLabelAtom,
    FilterValueAtom,
    FilterDropdownAtom,
  },
  data() {
    return {
      isExpanded: this.writeOnly,
    }
  },
  computed: {
    filtersMapped() {
      return this.filters.map(filter => {
        return {
          ...filter,
          key: `${filter.id}_${this.isExpanded ? 'expanded' : 'collapsed'}`,
        }
      })
    },
  },
}
</script>
