var render = function () {
  var _vm$assets;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetListMoleculeStyled', [_vm.isLoading ? _c('TalpaLoaderWrapper') : ((_vm$assets = _vm.assets) === null || _vm$assets === void 0 ? void 0 : _vm$assets.length) < 1 ? _c('NoDataAtom') : [_vm._l(_vm.assets, function (asset) {
    return [_c('AssetMolecule', {
      key: asset.id,
      staticClass: "row",
      attrs: {
        "asset": asset,
        "interval": _vm.interval
      }
    })];
  })], _c('ScrollToTopButtonMolecule', {
    attrs: {
      "containerId": 'app-main',
      "scrollThreshold": 2500
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }